.navbar {
    background-color: #d7e0ee;
    height: 70px!important;
}

.navbar-item {
    color: white;
}

p.navbar-item {
    font-size: 20px;
    font-weight: bold;
}

.is-primary {
    color: black!important;
}

.card-wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-wrapper > .card {
    width: 25%;
}

.wrapper {
    padding: 20px;
}

.column .card-content {
    display: flex;
    justify-content: space-between;
}

.is-right {
    text-align: right;
}

.is-right > .button {
    margin: 10px;
    font-weight: bold;
    border-radius: 10px;
}

.table {
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
}

.chip {
    background-color: #eaf4fb;
    border-radius: 10px;
    padding: 2px 10px;
}